import React from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Drawer,
  Tooltip,
  DatePicker,
  Select,
  InputNumber,
  Modal,
  Divider,
  Card,
  Typography,
  Popconfirm,
} from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import toast from "react-hot-toast";
import { useInvoices } from "../../Hooks/Account/useInvoices";
import { useDispatch } from "react-redux";

const { Option } = Select;
const { Text } = Typography;

const Index = () => {
  const dispatch = useDispatch();

  const { getAllInvoices, deleteInvoices, createInvoice } = useInvoices();

  const [createInvoiceData, setCreateInvoiceData] = React.useState({
    employeeName: "",
    employeeEmail: "",
    invoiceNumber: "",
    invoiceDate: dayjs().startOf("month"),
    salaryMonth: dayjs().subtract(1, "month"),
    designation: "",
    company: "",
    usdAmount: 0,
    bonusAmount: 0,
    expenseAmount: 0,
  });

  const [tableData, settableData] = React.useState([]);
  const [searchTableData, setsearchTableData] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");

  const handleChange = (field, value) => {
    setCreateInvoiceData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    if (!createInvoiceData.employeeName) {
      toast.error("Please select employee");
    } else if (!createInvoiceData.employeeEmail) {
      toast.error("Please enter employee email");
    } else if (!createInvoiceData.invoiceNumber) {
      toast.error("Please enter invoice number");
    } else if (!createInvoiceData.invoiceDate) {
      toast.error("Please select invoice date");
    } else if (!createInvoiceData.salaryMonth) {
      toast.error("Please select salary month");
    } else if (!createInvoiceData.designation) {
      toast.error("Please select designation");
    } else if (!createInvoiceData.company) {
      toast.error("Please select company");
    } else if (!createInvoiceData.usdAmount) {
      toast.error("Please enter usd amount");
    } else {
      setIsModalOpen(true);
    }
  };

  const create_invoice = async () => {
    try {
      const body = {
        employee_name: createInvoiceData.employeeName,
        employee_email: createInvoiceData.employeeEmail,
        invoice_no: createInvoiceData.invoiceNumber,
        invoice_date: dayjs(createInvoiceData.invoiceDate).format("DD-MM-YYYY"), // "01-07-2022",
        salary_month: dayjs(createInvoiceData.salaryMonth).format("MMMM YYYY"), // "June 2022",
        designation: createInvoiceData.designation,
        USD_amount: createInvoiceData.usdAmount,
        bonus_amount: createInvoiceData.bonusAmount,
        expense_amount: createInvoiceData.expenseAmount,
        company_type: createInvoiceData.company,
      };
      const data = await createInvoice(body, dispatch);
      console.log(data);
      generatePDF();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOk = () => {
    // createInvoiceData
    create_invoice();
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    get_all_invoices();
  }, []);

  const get_all_invoices = async () => {
    try {
      const data = await getAllInvoices(dispatch);
      if (data) {
        settableData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delete_invoices = async (id) => {
    try {
      console.log(id);
      const data = await deleteInvoices(id, dispatch);
      if (data.code === 2001) {
        toast.success(data.message);
        get_all_invoices();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generatePDF = () => {
    const input = document.getElementById("invoice-content");

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const topPadding = 10;
      let heightLeft = imgHeight;

      let position = topPadding;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - topPadding;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + topPadding;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");
    });
  };

  const columns = [
    {
      title: "Invoice No.",
      dataIndex: "invoice_no",
      key: "invoice_no",
    },
    {
      title: "Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
    },
    {
      title: "USD($)",
      dataIndex: "USD_amount",
      key: "USD_amount",
    },
    {
      title: "Bonus($)",
      dataIndex: "bonus_amount",
      key: "bonus_amount",
    },
    {
      title: "Expense($)",
      dataIndex: "expense_amount",
      key: "expense_amount",
    },
    {
      title: "Total($)",
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <div>
          {parseFloat(record.USD_amount) +
            parseFloat(record.bonus_amount) +
            parseFloat(record.expense_amount)}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="View">
            <Button
              type="text"
              style={{ color: "#1677ff" }}
              icon={<EyeOutlined />}
              onClick={() => {
                const invoiceData = JSON.parse(record.invoice_data);
                setCreateInvoiceData({
                  employeeName: invoiceData.employee_name,
                  employeeEmail: invoiceData.employee_email,
                  invoiceNumber: invoiceData.invoice_no,
                  invoiceDate: invoiceData.invoice_date,
                  salaryMonth: invoiceData.salary_month,
                  designation: invoiceData.designation,
                  company: invoiceData.company_type,
                  usdAmount: invoiceData.USD_amount ?? "0",
                  bonusAmount: invoiceData.bonus_amount ?? "0",
                  expenseAmount: invoiceData.expense_amount ?? "0",
                });
                showModal();
              }}
            ></Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Delete?"
              description="Are you sure to delete this invoice?"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              onConfirm={() => {
                if (record.id) {
                  delete_invoices(record.id);
                } else {
                  toast.error("Something went wrong");
                }
              }}
              placement="bottom"
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const searchData = (text) => {
    const newData = tableData.filter((item) => {
      return (
        String(item.invoice_no).includes(text) ||
        String(item.USD_amount).includes(text) ||
        String(item.bonus_amount).includes(text) ||
        String(item.expense_amount).includes(text)
      );
    });
    setsearchTableData(newData);
  };

  return (
    <div>
      <Row style={{ marginBottom: "20px" }} gutter={[16, 24]}>
        <Col span={6}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onChange={(e) => {
              setsearchText(e.target.value);
              searchData(e.target.value);
            }}
          />
        </Col>
        <Col span={6}>
          <Button
            onClick={showDrawer}
            size="large"
            style={{ width: "100%" }}
            type="primary"
          >
            Create Invoice
          </Button>
        </Col>
      </Row>
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={searchText ? searchTableData : tableData}
      />
      <Drawer
        title="Create Invoice"
        width={480}
        onClose={onClose}
        open={visible}
        styles={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={showModal} type="primary">
              View Invoice
            </Button>
          </div>
        }
      >
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Employee Name:</div>
          <Input
            placeholder="Employee Name"
            value={createInvoiceData.employeeName}
            onChange={(e) => handleChange("employeeName", e.target.value)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Employee Email:</div>
          <Input
            placeholder="Employee Email"
            value={createInvoiceData.employeeEmail}
            onChange={(e) => handleChange("employeeEmail", e.target.value)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>
            Invoice # &nbsp;
            <Tooltip title="Unique Invoice Number">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <Input
            placeholder="Invoice #"
            value={createInvoiceData.invoiceNumber}
            onChange={(e) => handleChange("invoiceNumber", e.target.value)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Invoice Date:</div>
          <DatePicker
            style={{ width: "100%" }}
            value={
              createInvoiceData.invoiceDate
                ? dayjs(createInvoiceData.invoiceDate)
                : dayjs().startOf("month")
            }
            onChange={(date) => handleChange("invoiceDate", date)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Select Salary Month:</div>
          <DatePicker
            value={
              createInvoiceData.salaryMonth
                ? dayjs(createInvoiceData.salaryMonth)
                : dayjs().subtract(1, "month")
            }
            picker="month"
            format="MMMM YYYY"
            style={{ width: "100%" }}
            onChange={(date) => handleChange("salaryMonth", date)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Select Designation:</div>
          <Select
            placeholder="Select Designation"
            value={createInvoiceData.designation}
            style={{ width: "100%" }}
            onChange={(e) => handleChange("designation", e)}
          >
            <Option value="developer">Developer</Option>
            <Option value="designer">Designer</Option>
            <Option value="manager">Manager</Option>
          </Select>
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Company:</div>
          <Select
            placeholder="Select Company"
            value={createInvoiceData.company}
            style={{ width: "100%" }}
            onChange={(e) => handleChange("company", e)}
          >
            <Option value="trackShip">TrackShip</Option>
            <Option value="zorem">Zorem</Option>
          </Select>
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>USD Amount:</div>
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            type="number"
            placeholder="USD Amount"
            value={createInvoiceData.usdAmount}
            onChange={(value) => handleChange("usdAmount", value)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Bonus Amount:</div>
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            type="number"
            placeholder="Bonus Amount"
            value={createInvoiceData.bonusAmount}
            onChange={(e) => handleChange("bonusAmount", e)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Expense Amount:</div>
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            type="number"
            placeholder="Expense Amount"
            value={createInvoiceData.expenseAmount}
            onChange={(e) => handleChange("expenseAmount", e)}
          />
        </div>
      </Drawer>
      <Modal
        title="View Invoice"
        open={isModalOpen}
        onOk={handleOk}
        centered
        okText="Save & Print"
        width={750}
        onCancel={handleCancel}
      >
        <div id="invoice-content">
          <Card
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderWidth: "1px",
              borderColor: "#cfcfcf",
            }}
          >
            <Row justify="space-between" style={{ marginBottom: "20px" }}>
              <Col>
                <div style={{ color: "#888", fontSize: "12px" }}>
                  <b>FROM</b>
                </div>
                <Text style={{ fontWeight: "bold" }}>
                  {createInvoiceData.employeeName}
                </Text>
                <br />
                <Text> Ahmedabad </Text>
                <br />
                <Text>{createInvoiceData.employeeEmail}</Text>
              </Col>
              {createInvoiceData.company === "trackship" ? (
                <Col>
                  <div style={{ color: "#888", fontSize: "12px" }}>
                    <b>TO</b>
                  </div>
                  <Text style={{ fontWeight: "bold" }}> TrackShip, Inc. </Text>
                  <br />
                  <Text> 600 N Broad Street </Text>
                  <br />
                  <Text> Suite 5 # 544 </Text>
                  <br />
                  <Text> Middletown, DE 19709 </Text>
                  <br />
                  <Text> info@trackship.com </Text>
                </Col>
              ) : (
                <Col>
                  <div style={{ color: "#888", fontSize: "12px" }}>
                    <b>TO</b>
                  </div>
                  <Text style={{ fontWeight: "bold" }}>
                    {" "}
                    zorem int'l trade LTD.{" "}
                  </Text>
                  <br />
                  <Text> Israel </Text>
                  <br />
                  <Text> info@zorem.com </Text>
                </Col>
              )}
            </Row>

            <Divider />

            <Row justify="space-between" style={{ marginBottom: "20px" }}>
              <Col>
                <Text>
                  <b>Invoice No:</b> {createInvoiceData.invoiceNumber}
                </Text>
                <br />
                <Text>
                  <b>Invoice Date:</b>{" "}
                  {dayjs(createInvoiceData.invoiceDate, "DD-MM-YYYY").format(
                    "MMMM Do, YYYY"
                  )}
                </Text>
              </Col>
            </Row>

            <Row justify="start">
              <Col span={24}>
                <div style={{ width: "100%", marginBottom: "40px" }}>
                  <div
                    style={{
                      borderBottom: "1px solid #dddddd",
                      borderTop: "1px solid #dddddd",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <b>Item</b>
                    </div>
                    <div style={{ textAlign: "right", marginRight: "10px" }}>
                      <b>Subtotal</b>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: "1px solid #dddddd",
                    }}
                  >
                    <div>
                      {dayjs(createInvoiceData.invoiceDate, "DD-MM-YYYY")
                        .subtract(1, "month")
                        .format("MMMM")}{" "}
                      Invoice
                    </div>
                    <div style={{ textAlign: "right" }}>
                      ${createInvoiceData.usdAmount}
                    </div>
                  </div>
                  {createInvoiceData.bonusAmount &&
                  parseInt(createInvoiceData.bonusAmount) > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderBottom: "1px solid #dddddd",
                      }}
                    >
                      <div>Bonus</div>
                      <div style={{ textAlign: "right" }}>
                        ${createInvoiceData.bonusAmount}
                      </div>
                    </div>
                  ) : null}
                  {createInvoiceData.expenseAmount &&
                  parseInt(createInvoiceData.expenseAmount) > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderBottom: "1px solid #dddddd",
                      }}
                    >
                      <div>Expense</div>
                      <div style={{ textAlign: "right" }}>
                        ${createInvoiceData.expenseAmount}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row justify="end">
              <Col span={10}>
                <div style={{ width: "100%", marginBottom: "40px" }}>
                  <div
                    style={{
                      borderBottom: "1px solid #dddddd",
                      borderTop: "1px solid #dddddd",
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px 0",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginLeft: "10px" }}>
                      <b>Invoice Summary</b>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: "1px solid #dddddd",
                    }}
                  >
                    <div>Total</div>
                    <div style={{ textAlign: "right" }}>
                      $
                      {(createInvoiceData.usdAmount ||
                      createInvoiceData.usdAmount > 0
                        ? parseFloat(createInvoiceData.usdAmount)
                        : 0) +
                        (createInvoiceData.bonusAmount ||
                        createInvoiceData.bonusAmount > 0
                          ? parseFloat(createInvoiceData.bonusAmount)
                          : 0) +
                        (createInvoiceData.expenseAmount ||
                        createInvoiceData.expenseAmount > 0
                          ? parseFloat(createInvoiceData.expenseAmount)
                          : 0)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
