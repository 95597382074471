import { useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "./../../Config/Config";
import toast from "react-hot-toast";
import { setLoader } from "./../../Redux/Slice/loaderSlice";

export const useAuth = () => {
  const alertBox = (text) => {
    return toast.error(text);
  };

  //login api
  const login = useCallback(async (body, dispatch) => {
    dispatch(setLoader(true));
    try {
      const { data } = await axios.post(
        `${BASE_URL.BASE_API_URL}company/login`,
        body,
        {
          headers: {},
        }
      );
      if (data.error) {
        alertBox(data.msg);
      }
      dispatch(setLoader(false));
      return data.data;
    } catch (error) {
      dispatch(setLoader(false));
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        alertBox(error.response.data.msg);
      } else {
        alertBox(error.message);
      }
    }
  }, []);

  return {
    login,
  };
};
