import Invoices from "../Pages/Invoices";
import Vacation from "../Pages/Vacation";
import OfficialHolidays from "../Pages/OfficialHolidays";
import Settings from "../Pages/Settings";
import AdminInvoices from "../AdminPages/Invoices";
import AdminVacation from "../AdminPages/Vacation";
import AdminReport from "../AdminPages/Report";

export const routes_array = [
  {
    path: "/",
    element: <Invoices />,
  },
  {
    path: "/vacation",
    element: <Vacation />,
  },
  {
    path: "/official-holidays",
    element: <OfficialHolidays />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
];

export const admin_routes_array = [
  {
    path: "",
    element: <AdminInvoices />,
  },
  {
    path: "vacation",
    element: <AdminVacation />,
  },
  {
    path: "report",
    element: <AdminReport />,
  },
];
