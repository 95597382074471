import React from "react";
import { Card, Input, Button } from "antd";
import { UserOutlined, MailOutlined, LockOutlined } from "@ant-design/icons";

const Settings = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card title="" bordered={true} style={{ width: 400 }}>
        <div style={{ marginBottom: 16 }}>
          <Input placeholder="Name" prefix={<UserOutlined />} size="large" />
        </div>

        <div style={{ marginBottom: 16 }}>
          <Input
            placeholder="Email"
            prefix={<MailOutlined />}
            type="email"
            size="large"
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <Input.Password
            placeholder="Password"
            prefix={<LockOutlined />}
            size="large"
          />
        </div>

        <Button type="primary" style={{ width: "100%" }}>
          Save Changes
        </Button>
      </Card>
    </div>
  );
};

export default Settings;
