import React, { useState, useEffect } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileTextOutlined,
  UserOutlined,
  LogoutOutlined,
  CoffeeOutlined,
  DotChartOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Layout, Menu, Popover } from "antd";

// Style
import "./style.css";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { admin_routes_array } from "../../Routes";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setUser } from "../../Redux/Slice/userSlice";

const { Header, Sider, Content } = Layout;

const Layoutpage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);

  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState("1");

  useEffect(() => {
    const path = location.pathname.substr(1);
    setCurrent(path || "1");
  }, [location]);

  const handleClick = (e) => {
    setCurrent(e.key ?? "1");
  };

  const handlePopOverClick = (e) => {
    if (e.key === "4") {
      dispatch(setLogin(false));
      dispatch(setUser({}));
      navigate("/login");
      window.localStorage.removeItem("ts_invoices");
    } else if (e.key === "3") {
      navigate("/admin/report");
    } else if (e.key === "2") {
      navigate("/admin/vacation");
    } else if (e.key === "1") {
      navigate("/admin/");
    }
  };

  const content = (
    <div>
      <Menu
        theme="light"
        style={{ borderInlineEnd: "none" }}
        onClick={handlePopOverClick}
      >
        <Menu.Item key="1">
          <span>Invoices</span>
        </Menu.Item>
        <Menu.Item key="2">
          <span>Vacation</span>
        </Menu.Item>
        <Menu.Item key="3">
          <span>Report</span>
        </Menu.Item>
        <Menu.Item key="4">
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    </div>
  );

  const renderMenu = () => {
    return [
      {
        key: "1",
        label: (
          <Link to="/admin/">
            <FileTextOutlined />
            <span>Invoices</span>
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link to="/admin/vacation">
            <CoffeeOutlined />
            <span>Vacation</span>
          </Link>
        ),
      },
      {
        key: "3",
        label: (
          <Link to="/admin/report">
            <DotChartOutlined />
            <span>Report</span>
          </Link>
        ),
      },
      {
        key: "4",
        label: (
          <span
            onClick={() => {
              dispatch(setLogin(false));
              dispatch(setUser({}));
              navigate("/login");
              window.localStorage.removeItem("ts_invoices");
            }}
          >
            <LogoutOutlined />
            <span>Logout</span>
          </span>
        ),
      },
    ];
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme="dark"
        style={{
          // backgroundColor: "#ffffff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: !collapsed ? "50px 0px" : "30px 0px",
          }}
        >
          <img
            src={
              !collapsed
                ? require("../../Images/logo-white.png")
                : require("../../Images/icon-white.png")
            }
            alt="logo"
            height={!collapsed ? 30 : 40}
          />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          onClick={handleClick}
          mode="inline"
          items={renderMenu()}
        />
      </Sider>
      <Layout>
        <Header
          theme="dark"
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
              color: "#fff",
            }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => navigate("/")}
              style={{
                marginRight: 10,
                backgroundColor: "transparent",
                color: "#fff",
              }}
              type="dashed"
            >
              Home
            </Button>
            <Popover placement="bottomLeft" content={content} title="">
              <Avatar
                src={require("./../../Images/icon.png")}
                style={{
                  backgroundColor: "#fff",
                  verticalAlign: "middle",
                  marginRight: 15,
                }}
                size="default"
                gap={2}
                icon={<UserOutlined />}
              ></Avatar>
            </Popover>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 24px",
            padding: 24,
            minHeight: 280,
            background: "#ffffff",
            borderRadius: 10,
          }}
        >
          <Routes>
            {admin_routes_array.map((route) => {
              return <Route path={route.path} element={route.element} />;
            })}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Layoutpage;
