import React, { useEffect } from "react";
import {
  Table,
  Button,
  Tooltip,
  Row,
  Col,
  Select,
  Popconfirm,
  Drawer,
  Input,
  Pagination,
} from "antd";
import {
  EyeOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import toast from "react-hot-toast";
import { useAdminVacation } from "../../Hooks/Admin/useVacation";
import { useDispatch } from "react-redux";
import { useAdminInvoices } from "../../Hooks/Admin/useInvoices";

const Index = () => {
  const dispatch = useDispatch();

  const { getAllVacations, changeStatus } = useAdminVacation();
  const { getAllUser } = useAdminInvoices();

  const [selectVacation, setselectVacation] = React.useState({});

  const [visible, setVisible] = React.useState(false);
  const [startpoint, setstartpoint] = React.useState(0);
  const [currentpage, setcurrentpage] = React.useState(1);
  const [totalData, settotalData] = React.useState(0);

  const [tableCount, settableCount] = React.useState(50);

  const [tableData, settableData] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");

  const [selectedUser, setselectedUser] = React.useState("");
  const [userList, setuserList] = React.useState([]);

  React.useEffect(() => {
    get_all_valcation();
  }, [selectedUser]);

  React.useEffect(() => {
    get_all_users();
  }, []);

  const get_all_users = async () => {
    try {
      const data = await getAllUser(dispatch);
      if (data.length > 0) {
        const arr = [];
        data.map((item) => {
          arr.push({ label: item.name, value: item.id });
        });
        setuserList(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_all_valcation = async (page, count) => {
    const pagesizes = count ? count : tableCount;
    try {
      const data = await getAllVacations(
        {
          search: searchText,
          start: parseInt(page * pagesizes - pagesizes),
          length: parseInt(pagesizes),
          user_id: selectedUser,
        },
        dispatch
      );
      if (data) {
        settableData(data.data);
        setstartpoint(data.start);
        settotalData(data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user_id",
      key: "user_id",
      align: "center",
      render(text) {
        return <div>{userList.find((item) => item.value === text)?.label}</div>;
      },
    },
    {
      title: "Requested Time",
      dataIndex: "created_time",
      key: "created_time",
      align: "center",
    },
    {
      title: "Vacation Days",
      dataIndex: "vacation_days",
      key: "vacation_days",
      align: "center",
    },
    {
      title: "Work F Home",
      dataIndex: "wfh_days",
      key: "wfh_days",
      align: "center",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
      align: "center",
      width: "30%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => (
        <span
          style={{
            color: text === "" ? "grey" : text === "approve" ? "green" : "red",
          }}
        >
          {text === "" ? "Pending" : text === "approve" ? "Approve" : "Reject"}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <>
          {!record.status && (
            <>
              <Tooltip title="Approve">
                <Popconfirm
                  title="Approve?"
                  description="Are you sure to approve this request?"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "green",
                      }}
                    />
                  }
                  onConfirm={() => {
                    toast.success("Request approved successfully");
                  }}
                  placement="bottom"
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    icon={<CheckOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip title="Reject">
                <Popconfirm
                  title="Reject?"
                  description="Are you sure to reject this request?"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => {
                    toast.success("Request rejected successfully");
                  }}
                  placement="bottom"
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    style={{ marginLeft: "8px" }}
                    icon={<CloseOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip title="View">
                <Button
                  onClick={() => {
                    const pearshedData = JSON.parse(record.vacation_data);
                    setselectVacation({
                      ...record,
                      vacation_data: pearshedData,
                    });
                    showDrawer();
                  }}
                  style={{ marginLeft: "8px" }}
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </>
          )}
          {record.status === "approve" && (
            <>
              <Tooltip title="Reject">
                <Popconfirm
                  title="Reject?"
                  description="Are you sure to reject this request?"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => {
                    toast.success("Request rejected successfully");
                  }}
                  placement="bottom"
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger icon={<CloseOutlined />} />
                </Popconfirm>
              </Tooltip>
              <Tooltip title="View">
                <Button
                  onClick={() => {
                    const pearshedData = JSON.parse(record.vacation_data);
                    setselectVacation({
                      ...record,
                      vacation_data: pearshedData,
                    });
                    showDrawer();
                  }}
                  style={{ marginLeft: "8px" }}
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </>
          )}
          {record.status === "reject" && (
            <>
              <Tooltip title="Approve">
                <Popconfirm
                  title="Approve?"
                  description="Are you sure to approve this request?"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "green",
                      }}
                    />
                  }
                  onConfirm={() => {
                    toast.success("Request approved successfully");
                  }}
                  placement="bottom"
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    icon={<CheckOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip title="View">
                <Button
                  onClick={() => {
                    const pearshedData = JSON.parse(record.vacation_data);
                    setselectVacation({
                      ...record,
                      vacation_data: pearshedData,
                    });
                    showDrawer();
                  }}
                  style={{ marginLeft: "8px" }}
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: "20px" }} gutter={[16, 24]}>
        <Col span={6}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onBlur={() => {
              get_all_valcation();
            }}
            onPressEnter={() => {
              get_all_valcation();
            }}
            onChange={(e) => {
              if (e.target.value) {
                setsearchText(e.target.value);
              } else {
                setsearchText("");
                get_all_valcation();
              }
            }}
          />
        </Col>
        <Col span={6}>
          <Select
            size="large"
            style={{ width: "100%" }}
            defaultValue={""}
            placeholder="Select User"
            options={[{ value: "", label: "All Users" }, ...userList]}
            onChange={(e) => {
              setselectedUser(e);
            }}
          />
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 0 10px 0",
        }}
        className=""
      >
        <span>
          Showing
          <strong> {tableData.length > 0 ? startpoint + 1 : 0} </strong> to
          <strong>
            &nbsp;
            {startpoint + (tableCount ? parseInt(tableCount) : 50) > totalData
              ? totalData
              : startpoint + (tableCount ? parseInt(tableCount) : 50)}
            &nbsp;
          </strong>
          of <strong> {totalData}</strong> entries
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Pagination
            current={currentpage}
            onChange={(e) => {
              setcurrentpage(e);
              get_all_valcation(e);
            }}
            pageSize={tableCount ? parseInt(tableCount) : 50}
            total={totalData}
            showSizeChanger={false}
          />
        </div>
      </div>
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={tableData}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <span>
          Showing
          <strong> {tableData.length > 0 ? startpoint + 1 : 0} </strong> to
          <strong>
            &nbsp;
            {startpoint + (tableCount ? parseInt(tableCount) : 50) > totalData
              ? totalData
              : startpoint + (tableCount ? parseInt(tableCount) : 50)}
            &nbsp;
          </strong>
          of <strong> {totalData}</strong> entries
        </span>
      </div>
      <div
        className="paginationSelect"
        style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
      >
        <Pagination
          showQuickJumper
          current={currentpage}
          onChange={(e, pagesize) => {
            setcurrentpage(e);
            settableCount(pagesize);
            get_all_valcation(e, pagesize);
          }}
          defaultPageSize={tableCount ? parseInt(tableCount) : 50}
          total={totalData}
          showSizeChanger
          pageSizeOptions={[50, 100, 200, 500]}
        />
      </div>
      <Drawer
        title="Vacation Details"
        width={400}
        onClose={onClose}
        open={visible}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Back
            </Button>
            <Button type="primary" danger>
              Reject
            </Button>
          </div>
        }
      >
        <Row>
          <Col span={12}>
            <b>Name:</b>
          </Col>
          <Col span={12}>
            {userList.find((x) => x.value === selectVacation?.user_id)?.label}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>Status:</b>
          </Col>
          <Col span={12}>
            {selectVacation?.status === "approved" ? "Approved" : "Rejected"}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>Vacation:</b>
          </Col>
          <Col span={12}>{selectVacation?.vacation_days} days</Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>Work from Home:</b>
          </Col>
          <Col span={12}>{selectVacation?.wfh_days} days</Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>Reason:</b>
          </Col>
          <Col span={12}>{selectVacation?.reason}</Col>
        </Row>

        <Table
          columns={[
            {
              title: "Date",
              dataIndex: "vacation_date",
              key: "vacation_date",
            },
            {
              title: "Timing",
              dataIndex: "timing",
              key: "timing",
              render: (text, record) => (
                <div>
                  {record.leave_time == "full" && record.leave_type == "leave"
                    ? "Full day Vacation"
                    : null}
                  {record.leave_time == "full" && record.leave_type == "wfh"
                    ? "Full day Work from Home"
                    : null}
                  {record.leave_time == "half" &&
                  record.leave_type == "leave" &&
                  record.after_before == "after"
                    ? "Half day Vacation After Noon"
                    : null}
                  {record.leave_time == "half" &&
                  record.leave_type == "wfh" &&
                  record.after_before == "after"
                    ? "Half day Work from Home After Noon"
                    : null}
                  {record.leave_time == "half" &&
                  record.leave_type == "leave" &&
                  record.after_before == "before"
                    ? "Half day Vacation Before Noon"
                    : null}
                  {record.leave_time == "half" &&
                  record.leave_type == "wfh" &&
                  record.after_before == "after"
                    ? "Half day Work from Home Before Noon"
                    : null}
                </div>
              ),
            },
          ]}
          dataSource={selectVacation?.vacation_data}
          pagination={false}
          style={{ marginTop: 20 }}
        />
      </Drawer>
    </>
  );
};

export default Index;
