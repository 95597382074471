import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./Slice/loaderSlice";
import userReducer from "./Slice/userSlice";

const store = configureStore({
  reducer: {
    loader: loaderReducer,
    user: userReducer,
  },
});

export default store;
