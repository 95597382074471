import React from "react";
import { Table } from "antd";

const data = [
  {
    key: 1,
    date: "14-Jan-2024",
    day: "Sunday",
    festival: "Makar Sankranti",
    note: "",
  },
  {
    key: 2,
    date: "15-Jan-2024",
    day: "Monday",
    festival: "Vasi Uttarayan",
    note: "Extra",
  },
  {
    key: 3,
    date: "22-Jan-2024",
    day: "Monday",
    festival: "Ayodhya Ram Mandir day",
    note: "",
  },
  {
    key: 4,
    date: "26-Jan-2024",
    day: "Friday",
    festival: "Republic Day",
    note: "",
  },
  {
    key: 5,
    date: "8-Mar-2024",
    day: "Monday",
    festival: "Maha Shivratri",
    note: "Cancel",
  },
  {
    key: 6,
    date: "25-Mar-2024",
    day: "Monday",
    festival: "Holi/Dhuleti",
    note: "",
  },
  {
    key: 7,
    date: "29-Mar-2024",
    day: "Friday",
    festival: "Good Friday",
    note: "Cancel",
  },
  {
    key: 8,
    date: "11-Apr-2024",
    day: "Thursday",
    festival: "Ramjan-Eid",
    note: "Cancel",
  },
  {
    key: 9,
    date: "14-Apr-2024",
    day: "Sunday",
    festival: "Baba Saheb Ambedkar's Birthday",
    note: "Cancel",
  },
  {
    key: 10,
    date: "17-Apr-2024",
    day: "Wednesday",
    festival: "Shree Ram Navmi",
    note: "Cancel",
  },
  {
    key: 11,
    date: "21-Apr-2024",
    day: "Sunday",
    festival: "Mahavir Janma Kalyanak",
    note: "Cancel",
  },
  {
    key: 12,
    date: "17-Jun-2024",
    day: "Monday",
    festival: "Bakri Eid",
    note: "Cancel",
  },
  {
    key: 13,
    date: "15-Aug-2024",
    day: "Thursday",
    festival: "Independence Day",
    note: "",
  },
  {
    key: 14,
    date: "19-Aug-2024",
    day: "Monday",
    festival: "Raksha Bandhan",
    note: "",
  },
  {
    key: 15,
    date: "26-Aug-2024",
    day: "Monday",
    festival: "Janmashtami",
    note: "",
  },
  {
    key: 16,
    date: "7-Sep-2024",
    day: "Saturday",
    festival: "Samvatsari",
    note: "Cancel",
  },
  {
    key: 17,
    date: "16-Sep-2024",
    day: "Monday",
    festival: "Bara Vafat",
    note: "Cancel",
  },
  {
    key: 18,
    date: "2-Oct-2024",
    day: "Wednesday",
    festival: "Mahatma Gandhi's Birthday",
    note: "Cancel",
  },
  {
    key: 19,
    date: "12-Oct-2024",
    day: "Saturday",
    festival: "Vijayadashami",
    note: "",
  },
  {
    key: 20,
    date: "31-Oct-2024",
    day: "Thursday",
    festival: "Sardar Vallabhbhai Patel's Birthday",
    note: "Cancel",
  },
  {
    key: 21,
    date: "31-Oct-2024",
    day: "Thursday",
    festival: "Diwali",
    note: "",
  },
  {
    key: 22,
    date: "2-Nov-2024",
    day: "Saturday",
    festival: "New Year Day",
    note: "",
  },
  {
    key: 23,
    date: "3-Nov-2024",
    day: "Sunday",
    festival: "Bhai Bij",
    note: "",
  },
  {
    key: 24,
    date: "4-Nov-2024",
    day: "Monday",
    festival: "Treej",
    note: "Extra",
  },
  {
    key: 25,
    date: "5-Nov-2024",
    day: "Tuesday",
    festival: "Choth",
    note: "Extra",
  },
  {
    key: 26,
    date: "6-Nov-2024",
    day: "Wednesday",
    festival: "Pacham",
    note: "Extra",
  },
  {
    key: 27,
    date: "25-Dec-2024",
    day: "Wednesday",
    festival: "Christmas",
    note: "",
  },
];

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Day",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "Festival",
    dataIndex: "festival",
    key: "festival",
  },
  {
    title: "Note",
    dataIndex: "note",
    key: "note",
  },
];

const OfficialHolidays = () => {
  return (
    <Table columns={columns} dataSource={data} bordered pagination={false} />
  );
};

export default OfficialHolidays;
