import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    isLogin: false,
  },
  reducers: {
    setUser(state, action) {
      state.data = action.payload;
    },
    setLogin(state, action) {
      state.isLogin = action.payload;
    },
  },
});

export const { setUser, setLogin } = userSlice.actions;
export default userSlice.reducer;
