import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

// Style
import "./App.css";

// Pages
import NotFound from "./Pages/NotFound";
import Layout from "./Pages/Layout";
import Login from "./Pages/Login";
import { routes_array, admin_routes_array } from "./Routes";
import Loader from "./Pages/Loader";
import AdminLayout from "./AdminPages/AdminLayout";
import { setLogin, setUser } from "./Redux/Slice/userSlice";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLogin = useSelector((state) => state.user.isLogin);
  const isLoading = useSelector((state) => state.loader.value);

  useEffect(() => {
    const data = window.localStorage.getItem("ts_invoices");
    if (data) {
      navigate("/");
      dispatch(setLogin(true));
      dispatch(setUser(JSON.parse(data)));
      return;
    }
    if (
      ["/", "/vacation", "/official-holidays", "/settings"].includes(
        window.location.pathname
      ) &&
      !isLogin
    ) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Routes>
        {/* Routes for authenticated users */}
        {isLogin ? (
          <>
            <Route path="/" element={<Layout />}>
              {routes_array.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                />
              ))}
            </Route>
            <Route path="/admin" element={<AdminLayout />}>
              {admin_routes_array.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                />
              ))}
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </>
        )}

        {/* Route for 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* Loader */}
      {isLoading && <Loader />}

      <Toaster
        position="top-right"
        toastOptions={{
          duration: 1500,
        }}
      />
    </>
  );
}

export default App;
