import React from "react";
import { Button, Form, Input } from "antd";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setLogin, setUser } from "./../../Redux/Slice/userSlice";
// Style
import "./style.css";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Hooks/Account/useAuth";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login } = useAuth();

  const onFinish = (values) => {
    loginAuth(values);
  };
  const onFinishFailed = (errorInfo) => {
    if (!errorInfo.values.username && !errorInfo.values.password) {
      toast.error("Please enter username and password");
    } else if (!errorInfo.values.username) {
      toast.error("Please enter username");
    } else if (!errorInfo.values.password) {
      toast.error("Please enter password");
    }
  };

  const loginAuth = async (obj) => {
    try {
      const body = {
        email: obj.username,
        password: obj.password,
      };

      const data = await login(body, dispatch);
      if (data) {
        window.localStorage.setItem("ts_invoices", JSON.stringify(data));
        dispatch(setLogin(true));
        dispatch(setUser(data));
        navigate("/");
        toast.success("Login Successfull");
      }
      console.log(data);
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-form-container">
      <div className="login-form-wrapper">
        <Form
          name="basic"
          labelCol={{
            span: 5,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            username: "brijesh@trackship.com",
            password: "ZUwN541#peNFjzzU",
          }}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 0,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default App;
