import React from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Tooltip,
  Modal,
  Divider,
  Card,
  Typography,
  Popconfirm,
  Pagination,
  Select,
} from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useAdminInvoices } from "../../Hooks/Admin/useInvoices";

const { Text } = Typography;

const Index = () => {
  const dispatch = useDispatch();

  const { getAllInvoices, deleteInvoices, getAllUser } = useAdminInvoices();

  const [createInvoiceData, setCreateInvoiceData] = React.useState({
    employeeName: "",
    employeeEmail: "",
    invoiceNumber: "",
    invoiceDate: null,
    salaryMonth: null,
    designation: "",
    company: "",
    usdAmount: 0,
    bonusAmount: 0,
    expenseAmount: 0,
  });

  const [tableData, settableData] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");

  const [selectedUser, setselectedUser] = React.useState("");

  const [startpoint, setstartpoint] = React.useState(0);
  const [currentpage, setcurrentpage] = React.useState(1);
  const [totalData, settotalData] = React.useState(0);

  const [tableCount, settableCount] = React.useState(50);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [userList, setuserList] = React.useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    generatePDF();
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    get_all_invoices();
  }, [selectedUser]);

  React.useEffect(() => {
    get_all_users();
  }, []);

  const get_all_users = async () => {
    try {
      const data = await getAllUser(dispatch);
      if (data.length > 0) {
        const arr = [];
        data.map((item) => {
          arr.push({ label: item.name, value: item.id });
        });
        setuserList(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_all_invoices = async (page, count) => {
    const pagesizes = count ? count : tableCount;
    try {
      const data = await getAllInvoices(
        {
          search: searchText,
          start: parseInt(page * pagesizes - pagesizes),
          length: parseInt(pagesizes),
          user_id: selectedUser,
        },
        dispatch
      );
      if (data) {
        settableData(data.data);
        setstartpoint(data.start);
        settotalData(data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delete_invoices = async (id) => {
    try {
      console.log(id);
      const data = await deleteInvoices(id, dispatch);
      if (data.code === 2001) {
        toast.success(data.message);
        get_all_invoices();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generatePDF = () => {
    const input = document.getElementById("invoice-content");

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const topPadding = 10;
      let heightLeft = imgHeight;

      let position = topPadding;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - topPadding;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + topPadding;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");
    });
  };

  const columns = [
    {
      title: "Invoice No.",
      dataIndex: "invoice_no",
      key: "invoice_no",
    },
    {
      title: "Name",
      dataIndex: "user_id",
      key: "user_id",
      align: "center",
      render(text) {
        return <div>{userList.find((item) => item.value === text)?.label}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
    },
    {
      title: "USD($)",
      dataIndex: "USD_amount",
      key: "USD_amount",
    },
    {
      title: "Bonus($)",
      dataIndex: "bonus_amount",
      key: "bonus_amount",
    },
    {
      title: "Expense($)",
      dataIndex: "expense_amount",
      key: "expense_amount",
    },
    {
      title: "Total($)",
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <div>
          {parseFloat(record.USD_amount) +
            parseFloat(record.bonus_amount) +
            parseFloat(record.expense_amount)}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="View">
            <Button
              type="text"
              style={{ color: "#1677ff" }}
              icon={<EyeOutlined />}
              onClick={() => {
                const invoiceData = JSON.parse(record.invoice_data);
                setCreateInvoiceData({
                  employeeName: invoiceData.employee_name,
                  employeeEmail: invoiceData.employee_email,
                  invoiceNumber: invoiceData.invoice_no,
                  invoiceDate: invoiceData.invoice_date,
                  salaryMonth: invoiceData.salary_month,
                  designation: invoiceData.designation,
                  company: invoiceData.company_type,
                  usdAmount: invoiceData.USD_amount ?? "0",
                  bonusAmount: invoiceData.bonus_amount ?? "0",
                  expenseAmount: invoiceData.expense_amount ?? "0",
                });
                showModal();
              }}
            ></Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Delete?"
              description="Are you sure to delete this invoice?"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              onConfirm={() => {
                if (record.id) {
                  delete_invoices(record.id);
                } else {
                  toast.error("Something went wrong");
                }
              }}
              placement="bottom"
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row style={{ marginBottom: "20px" }} gutter={[16, 24]}>
        <Col span={6}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onBlur={() => {
              get_all_invoices();
            }}
            onPressEnter={() => {
              get_all_invoices();
            }}
            onChange={(e) => {
              if (e.target.value) {
                setsearchText(e.target.value);
              } else {
                setsearchText("");
                get_all_invoices();
              }
            }}
          />
        </Col>
        <Col span={6}>
          <Select
            size="large"
            style={{ width: "100%" }}
            defaultValue={""}
            placeholder="Select User"
            options={[{ value: "", label: "All Users" }, ...userList]}
            onChange={(e) => {
              setselectedUser(e);
            }}
          />
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 0 10px 0",
        }}
        className=""
      >
        <span>
          Showing
          <strong> {tableData.length > 0 ? startpoint + 1 : 0} </strong> to
          <strong>
            &nbsp;
            {startpoint + (tableCount ? parseInt(tableCount) : 50) > totalData
              ? totalData
              : startpoint + (tableCount ? parseInt(tableCount) : 50)}
            &nbsp;
          </strong>
          of <strong> {totalData}</strong> entries
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Pagination
            current={currentpage}
            onChange={(e) => {
              setcurrentpage(e);
              get_all_invoices(e);
            }}
            pageSize={tableCount ? parseInt(tableCount) : 50}
            total={totalData}
            showSizeChanger={false}
          />
        </div>
      </div>
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={tableData}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <span>
          Showing
          <strong> {tableData.length > 0 ? startpoint + 1 : 0} </strong> to
          <strong>
            &nbsp;
            {startpoint + (tableCount ? parseInt(tableCount) : 50) > totalData
              ? totalData
              : startpoint + (tableCount ? parseInt(tableCount) : 50)}
            &nbsp;
          </strong>
          of <strong> {totalData}</strong> entries
        </span>
      </div>
      <div
        className="paginationSelect"
        style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
      >
        <Pagination
          showQuickJumper
          current={currentpage}
          onChange={(e, pagesize) => {
            setcurrentpage(e);
            settableCount(pagesize);
            get_all_invoices(e, pagesize);
          }}
          defaultPageSize={tableCount ? parseInt(tableCount) : 50}
          total={totalData}
          showSizeChanger
          pageSizeOptions={[50, 100, 200, 500]}
        />
      </div>
      <Modal
        title="View Invoice"
        open={isModalOpen}
        onOk={handleOk}
        centered
        okText="Print"
        width={750}
        onCancel={handleCancel}
      >
        <div id="invoice-content">
          <Card
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderWidth: "1px",
              borderColor: "#cfcfcf",
            }}
          >
            <Row justify="space-between" style={{ marginBottom: "20px" }}>
              <Col>
                <div style={{ color: "#888", fontSize: "12px" }}>
                  <b>FROM</b>
                </div>
                <Text style={{ fontWeight: "bold" }}>
                  {createInvoiceData.employeeName}
                </Text>
                <br />
                <Text> Ahmedabad </Text>
                <br />
                <Text>{createInvoiceData.employeeEmail}</Text>
              </Col>
              {createInvoiceData.company === "trackship" ? (
                <Col>
                  <div style={{ color: "#888", fontSize: "12px" }}>
                    <b>TO</b>
                  </div>
                  <Text style={{ fontWeight: "bold" }}> TrackShip, Inc. </Text>
                  <br />
                  <Text> 600 N Broad Street </Text>
                  <br />
                  <Text> Suite 5 # 544 </Text>
                  <br />
                  <Text> Middletown, DE 19709 </Text>
                  <br />
                  <Text> info@trackship.com </Text>
                </Col>
              ) : (
                <Col>
                  <div style={{ color: "#888", fontSize: "12px" }}>
                    <b>TO</b>
                  </div>
                  <Text style={{ fontWeight: "bold" }}>
                    {" "}
                    zorem int'l trade LTD.{" "}
                  </Text>
                  <br />
                  <Text> Israel </Text>
                  <br />
                  <Text> info@zorem.com </Text>
                </Col>
              )}
            </Row>

            <Divider />

            <Row justify="space-between" style={{ marginBottom: "20px" }}>
              <Col>
                <Text>
                  <b>Invoice No:</b> {createInvoiceData.invoiceNumber}
                </Text>
                <br />
                <Text>
                  <b>Invoice Date:</b>{" "}
                  {dayjs(createInvoiceData.invoiceDate, "YYYY-MM-DD").format(
                    "MMMM Do, YYYY"
                  )}
                </Text>
              </Col>
            </Row>

            <Row justify="start">
              <Col span={24}>
                <div style={{ width: "100%", marginBottom: "40px" }}>
                  <div
                    style={{
                      borderBottom: "1px solid #dddddd",
                      borderTop: "1px solid #dddddd",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <b>Item</b>
                    </div>
                    <div style={{ textAlign: "right", marginRight: "10px" }}>
                      <b>Subtotal</b>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: "1px solid #dddddd",
                    }}
                  >
                    <div>
                      {dayjs(createInvoiceData.invoiceDate, "YYYY-MM-DD")
                        .subtract(1, "month")
                        .format("MMMM")}{" "}
                      Invoice
                    </div>
                    <div style={{ textAlign: "right" }}>
                      ${createInvoiceData.usdAmount}
                    </div>
                  </div>
                  {createInvoiceData.bonusAmount &&
                  parseInt(createInvoiceData.bonusAmount) > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderBottom: "1px solid #dddddd",
                      }}
                    >
                      <div>Bonus</div>
                      <div style={{ textAlign: "right" }}>
                        ${createInvoiceData.bonusAmount}
                      </div>
                    </div>
                  ) : null}
                  {createInvoiceData.expenseAmount &&
                  parseInt(createInvoiceData.expenseAmount) > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderBottom: "1px solid #dddddd",
                      }}
                    >
                      <div>Expense</div>
                      <div style={{ textAlign: "right" }}>
                        ${createInvoiceData.expenseAmount}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row justify="end">
              <Col span={10}>
                <div style={{ width: "100%", marginBottom: "40px" }}>
                  <div
                    style={{
                      borderBottom: "1px solid #dddddd",
                      borderTop: "1px solid #dddddd",
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px 0",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginLeft: "10px" }}>
                      <b>Invoice Summary</b>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: "1px solid #dddddd",
                    }}
                  >
                    <div>Total</div>
                    <div style={{ textAlign: "right" }}>
                      $
                      {(createInvoiceData.usdAmount ||
                      createInvoiceData.usdAmount > 0
                        ? parseFloat(createInvoiceData.usdAmount)
                        : 0) +
                        (createInvoiceData.bonusAmount ||
                        createInvoiceData.bonusAmount > 0
                          ? parseFloat(createInvoiceData.bonusAmount)
                          : 0) +
                        (createInvoiceData.expenseAmount ||
                        createInvoiceData.expenseAmount > 0
                          ? parseFloat(createInvoiceData.expenseAmount)
                          : 0)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
