import React from "react";
import {
  Table,
  Button,
  Tooltip,
  Row,
  Col,
  Drawer,
  Descriptions,
  DatePicker,
  Select,
  Input,
} from "antd";
import {
  PlusOutlined,
  MailOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useVacation } from "../../Hooks/Account/useVacation";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const { Option } = Select;
const { TextArea } = Input;

const Vacation = () => {
  const dispatch = useDispatch();

  const { getAllVacation, requestVacation } = useVacation();

  const [vacationArray, setvacationArray] = React.useState([{}]);

  const [vacationData, setvacationData] = React.useState([]);

  const [visible, setVisible] = React.useState(false);

  const [resone, setresone] = React.useState("");

  React.useEffect(() => {
    get_all_vacation();
  }, []);

  const get_all_vacation = async () => {
    try {
      const data = await getAllVacation(dispatch);
      console.log(data);

      setvacationData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const request_vacation = async () => {
    try {
      const body = {
        reason: resone,
        vacation_data: vacationArray,
      };
      const data = await requestVacation(body, dispatch);
      console.log(data);
      if (data.code === 2001) {
        toast.success(data.message);
        onClose2();
      }
      setvacationData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [visible2, setVisible2] = React.useState(false);

  const showDrawer2 = () => {
    setVisible2(true);
  };

  const onClose2 = () => {
    setVisible2(false);
    setvacationArray([{}]);
    setresone("");
  };

  const columns = [
    {
      title: "REQUESTED TIME",
      dataIndex: "created_time",
      key: "created_time",
    },
    {
      title: "VACATION DAYS",
      dataIndex: "vacation_days",
      key: "vacation_days",
    },
    {
      title: "WORK FROM HOME",
      dataIndex: "wfh_days",
      key: "wfh_days",
    },
    {
      title: "REASON",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (text === "approve") {
          return <span style={{ color: "green" }}>Approve</span>;
        } else if (text === "reject") {
          return <span style={{ color: "red" }}>Reject</span>;
        }
        return text;
      },
    },
    {
      title: "ACTION",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Tooltip title="View">
          <Button onClick={showDrawer} type="link" icon={<EyeOutlined />} />
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Row style={{ marginBottom: "20px" }} gutter={[16, 24]}>
        <Col span={6}>
          <Button
            onClick={showDrawer2}
            size="large"
            style={{ width: "100%" }}
            type="primary"
          >
            Request Vacation
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={vacationData}
        pagination={false}
        bordered
      />
      <Drawer
        title="Vacation Request Details"
        width={400}
        onClose={onClose}
        open={visible}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={onClose} type="primary">
              Back
            </Button>
          </div>
        }
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="NAME">Aniket Navdiya</Descriptions.Item>
          <Descriptions.Item label="STATUS">Reject</Descriptions.Item>
          <Descriptions.Item label="VACATION">0.5 days</Descriptions.Item>
          <Descriptions.Item label="WORK FROM HOME">0 days</Descriptions.Item>
          <Descriptions.Item label="REASON">
            For appointment of driving license
          </Descriptions.Item>
          <Descriptions.Item label="DATE">2024-08-12</Descriptions.Item>
          <Descriptions.Item label="TIMING">
            Half days After noon Vacation
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
      <Drawer
        title="Request Vacation"
        width={700}
        onClose={onClose2}
        open={visible2}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                request_vacation();
              }}
              icon={<MailOutlined />}
              style={{ marginRight: 8 }}
              type="primary"
            >
              Send Email
            </Button>
            <Button icon={<ArrowLeftOutlined />} onClick={onClose2}>
              Back to Vacation
            </Button>
          </div>
        }
      >
        {vacationArray.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ marginBottom: 16, width: "30%" }}>
                <div style={{ marginBottom: 5 }}>Date</div>
                <DatePicker
                  onChange={(value) => {
                    const dataCopy = [...vacationArray];
                    dataCopy[index].vacation_date =
                      dayjs(value).format("YYYY-MM-DD");
                    setvacationArray(dataCopy);
                  }}
                  style={{ width: "100%" }}
                />
              </div>

              <div style={{ marginBottom: 16, width: "30%" }}>
                <div style={{ marginBottom: 5 }}>Full/Half</div>
                <Select
                  onChange={(value) => {
                    const dataCopy = [...vacationArray];
                    dataCopy[index].leave_time =
                      value === "Full" ? "full" : "half";
                    dataCopy[index].after_before =
                      value === "Full"
                        ? "full"
                        : value === "Half/Beforenoon"
                        ? "before"
                        : "after";
                    setvacationArray(dataCopy);
                  }}
                  defaultValue=""
                  style={{ width: "100%" }}
                >
                  <Option value="Full">Full</Option>
                  <Option value="Half/Beforenoon">Half/Before noon</Option>
                  <Option value="Half/Afternoon">Half/After noon</Option>
                </Select>
              </div>

              <div style={{ marginBottom: 16, width: "30%" }}>
                <div style={{ marginBottom: 5 }}>Vacation/Work from Home</div>
                <Select
                  onChange={(value) => {
                    const dataCopy = [...vacationArray];
                    dataCopy[index].leave_type = value;
                    setvacationArray(dataCopy);
                  }}
                  defaultValue=""
                  style={{ width: "100%" }}
                >
                  <Option value="Vacation">Vacation</Option>
                  <Option value="Work from Home">Work from Home</Option>
                </Select>
              </div>
              <Button
                onClick={() => {
                  if (vacationArray.length === 1) {
                    return;
                  }
                  const dataCopy = [...vacationArray];
                  const array = [];
                  for (let i = 0; i < dataCopy.length; i++) {
                    if (i !== index) {
                      array.push(dataCopy[i]);
                    }
                  }
                  setvacationArray(array);
                }}
                danger
                type="primary"
                icon={<DeleteOutlined />}
              ></Button>
            </div>
          );
        })}
        <div style={{ textAlign: "right", marginBottom: 16 }}>
          <Button
            onClick={() => {
              setvacationArray([...vacationArray, {}]);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Vacation
          </Button>
        </div>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 5 }}>Reason For Vacation</div>
          <TextArea
            onChange={(e) => setresone(e.target.value)}
            rows={4}
            placeholder="Enter reason for vacation"
          />
        </div>
      </Drawer>
    </div>
  );
};

export default Vacation;
