import { useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "./../../Config/Config";
import toast from "react-hot-toast";
import { setLoader } from "./../../Redux/Slice/loaderSlice";
import { useSelector } from "react-redux";

export const useInvoices = () => {
  const alertBox = (text) => {
    return toast.error(text);
  };

  const userData = useSelector((state) => state.user.data);

  const Headers = {
    "Account-Token": userData.token,
  };

  //Get all invoices
  const getAllInvoices = useCallback(async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const { data } = await axios.get(
        `${BASE_URL.BASE_API_URL}company/invoices`,
        {
          headers: {
            ...Headers,
          },
        }
      );
      if (data.error) {
        alertBox(data.msg);
      }
      dispatch(setLoader(false));
      return data.data;
    } catch (error) {
      dispatch(setLoader(false));
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        alertBox(error.response.data.msg);
      } else {
        alertBox(error.message);
      }
    }
  }, []);

  //delete invoices
  const deleteInvoices = useCallback(async (id, dispatch) => {
    dispatch(setLoader(true));
    try {
      const { data } = await axios.post(
        `${BASE_URL.BASE_API_URL}company/invoice/delete`,
        { id: id },
        {
          headers: {
            ...Headers,
          },
        }
      );
      if (data.error) {
        alertBox(data.msg);
      }
      dispatch(setLoader(false));
      return data.meta;
    } catch (error) {
      dispatch(setLoader(false));
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        alertBox(error.response.data.msg);
      } else {
        alertBox(error.message);
      }
    }
  }, []);

  const createInvoice = useCallback(async (body, dispatch) => {
    dispatch(setLoader(true));
    try {
      const { data } = await axios.post(
        `${BASE_URL.BASE_API_URL}company/invoice/create`,
        body,
        {
          headers: {
            ...Headers,
          },
        }
      );
      if (data.error) {
        alertBox(data.msg);
      }
      dispatch(setLoader(false));
      return data.meta;
    } catch (error) {
      dispatch(setLoader(false));
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        alertBox(error.response.data.msg);
      } else {
        alertBox(error.message);
      }
    }
  }, []);

  return {
    getAllInvoices,
    deleteInvoices,
    createInvoice,
  };
};
