import React from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Tooltip,
  Select,
  Modal,
  Divider,
  Card,
  Typography,
  Popconfirm,
  DatePicker,
} from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import toast from "react-hot-toast";

const { Text } = Typography;

const Index = () => {
  const [createInvoiceData, setCreateInvoiceData] = React.useState({
    employeeName: "",
    employeeEmail: "",
    invoiceNumber: "",
    invoiceDate: dayjs().startOf("month"),
    salaryMonth: dayjs().subtract(1, "month").startOf("month"),
    designation: "",
    company: "",
    usdAmount: 0,
    bonusAmount: 0,
    expenseAmount: 0,
  });

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    console.log(createInvoiceData);

    setIsModalOpen(true);
  };
  const handleOk = () => {
    generatePDF();
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const generatePDF = () => {
    const input = document.getElementById("invoice-content");

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const topPadding = 10;
      let heightLeft = imgHeight;

      let position = topPadding;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - topPadding;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + topPadding;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");
    });
  };

  const columns = [
    {
      title: "Vacation Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Vacation",
      dataIndex: "days",
      key: "days",
    },
  ];

  const data = [
    {
      key: "1",
      date: "2022-01-01",
      user: "User 1",
      days: 10,
    },
    {
      key: "2",
      date: "2022-01-01",
      user: "User 2",
      days: 20,
    },
  ];

  return (
    <div>
      <Row style={{ marginBottom: "20px" }} gutter={[16, 24]}>
        <Col span={6}>
          <Select
            size="large"
            style={{ width: "100%" }}
            defaultValue={""}
            placeholder="Select User"
            options={[
              { value: "", label: "All Users" },
              { value: "1", label: "User 1" },
              { value: "2", label: "User 2" },
            ]}
          ></Select>
        </Col>
        <Col span={6}>
          <Select
            size="large"
            style={{ width: "100%" }}
            placeholder="Select Months"
            defaultValue={""}
            options={[
              { value: "", label: "All Months" },
              { value: "1", label: "January" },
              { value: "2", label: "February" },
              { value: "3", label: "March" },
              { value: "4", label: "April" },
              { value: "5", label: "May" },
              { value: "6", label: "June" },
              { value: "7", label: "July" },
              { value: "8", label: "August" },
              { value: "9", label: "September" },
              { value: "10", label: "October" },
              { value: "11", label: "November" },
              { value: "12", label: "December" },
            ]}
          ></Select>
        </Col>
        <Col span={6}>
          <DatePicker
            style={{ width: "100%" }}
            size="large"
            maxDate={dayjs()}
            onChange={(date, dateString) => {
              console.log(date, dateString);
            }}
            picker="year"
          />
        </Col>
      </Row>
      <Table bordered pagination={false} columns={columns} dataSource={data} />
    </div>
  );
};

export default Index;
